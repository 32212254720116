<template>
  <div class="membership-selector">
    <a-input-search
      placeholder="Search..."
      style="width: 230px; margin-bottom: 20px"
      v-model="searchQuery"
    />

    <a-row :gutter="10" type="flex">
      <a-col :span="colSpan" v-for="(member, i) in membersToShow" :key="i">
        <div class="member-selector">
          <a-checkbox
            :checked="isMemberSelected(member)"
            @change="$emit('toggle-presence', member.id)"
          >
            {{ member.displayName }}
          </a-checkbox>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      searchQuery: "",
    };
  },
  props: ["selectedPresenceIds"],
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    ...mapGetters("admin", {
      membership: "membership",
    }),

    colSpan() {
      if (this.windowWidth > 1000) {
        return 8;
      }
      if (this.windowWidth > 600) {
        return 12;
      }
      return 24;
    },

    membershipSorted() {
      let vm = this;
      let selectedMembers = _.filter(this.membership, (member) => {
        return vm.isMemberSelected(member);
      });
      let unselectedMembers = _.filter(this.membership, (member) => {
        return !vm.isMemberSelected(member);
      });
      return _.flattenDeep([
        _.orderBy(selectedMembers, "displayName"),
        _.orderBy(unselectedMembers, "displayName"),
      ]);
    },

    membersToShow() {
      let members = this.membershipSorted;

      if (this.searchQuery && this.searchQuery.trim().length) {
        return _.filter(members, (member) => {
          return member.displayName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }

      return members;
    },
  },
  methods: {
    isMemberSelected(member) {
      return this.selectedPresenceIds.includes(member.id);
    },
  },
};
</script>

<style scoped lang="scss">
.member-selector {
  margin-bottom: 15px;
}
</style>