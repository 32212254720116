<template>
  <div class="admin-create-managed-group">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Create Managed Group</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Form -->
    <div v-if="!isLoading" class="form">
      <div class="top-row">
        <div class="left">
          <a-form-item label="Group Icon">
            <a-upload
              :multiple="false"
              :file-list="fileList"
              :before-upload="beforeUpload"
              :remove="handleRemove"
            >
              <a-button>
                <a-icon type="upload"></a-icon>
                Click to Upload
              </a-button>
            </a-upload>
          </a-form-item>
        </div>
        <div class="right">
          <a-form-item label="Group Name">
            <a-input
              size="large"
              v-model="managedGroupLocal.groupName"
            ></a-input>
          </a-form-item>
        </div>
      </div>

      <a-form-item style="margin-bottom: 0" label="Membership"></a-form-item>

      <membership-selector
        :selected-presence-ids="managedGroupLocal.presenceIds"
        @toggle-presence="togglePresenceSelected"
      ></membership-selector>
    </div>
    <!-- / Form -->

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../components/LoadingScreen.vue";
import MembershipSelector from "../../../components/Admin/MembershipSelector.vue";
const _ = require("lodash");
import chats from "../../../api/chats";

export default {
  components: { LoadingScreen, MembershipSelector },

  data() {
    return {
      managedGroupLocal: {
        displayName: "",
        presenceIds: [],
      },

      fileList: [],

      isSaving: false,
    };
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },

  methods: {
    goBack() {
      this.$router.push("/admin/managed-groups");
    },

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },

    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("Please upload a JPG or a PNG");
      }
      if (isJpgOrPng) {
        this.fileList = [file];
      }
      return false;
    },

    getValidationErrors() {
      let errors = [];
      if (this.managedGroupLocal.groupName.trim().length == 0) {
        errors.push("Please provide a group name");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      chats
        .createChat(this.tenantId, {
          AddMembers: this.managedGroupLocal.presenceIds,
          CreatedBy: this.myPresenceForOrg.id,
          DisplayName: this.managedGroupLocal.groupName,
          HasCustomAvatar: false,
          OwnerId: this.selectedOrganisation.id,
          Flags: 1, // managed group
        })
        .then((r) => {
          vm.$message.success("Managed group created successfully");
          if (this.fileList.length) {
            chats
              .addChatThumb(
                vm.tenantId,
                r.data.id,
                vm.myPresenceForOrg.id,
                vm.fileList[0]
              )
              .then(() => {
                vm.$router.push("/admin/managed-groups");
              })
              .catch((e) => {
                console.log(e);
                vm.$router.push("/admin/managed-groups");
                vm.$message.error("Error uploading file");
              });
          } else {
            vm.$router.push("/admin/managed-groups");
          }
        })
        .catch((e) => {
          console.log(e);
          this.$message.error("Error creating managed group");
          vm.isSaving = false;
        });
    },

    togglePresenceSelected(presenceId) {
      this.managedGroupLocal.presenceIds = _.xor(
        this.managedGroupLocal.presenceIds,
        [presenceId]
      );
    },
  },

  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    myPresenceForOrg() {
      return _.find(this.presences, {
        ownerId: this.selectedOrganisation.id,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.admin-create-managed-group {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>